import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../../Layout";
import Seo from "../../../components/Seo/Seo";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageBanner from "../../../components/PageBanner/PageBanner";
import TitlePage from "../../../Molecules/TitlePage/TitlePage";
import IntroPage from "../../../components/ContentType/IntroPage/IntroPage";

import TitleSection from "../../../Molecules/TitleSection/TitleSection";
import TextVisuelTwoCol from "../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol";
import ShapeBackground from "../../../Molecules/ShapeBackground/ShapeBackground";
import ScrollPageNav from "../../../components/ScrollPageNav/ScrollPageNav";

var classNames = require("classnames");

import "../engagements.scss";

const EngagementMecenat = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "slider-image.png" }) {
        childImageSharp {
          mobile: fixed(width: 456, height: 456) {
            ...GatsbyImageSharpFixed
          }
          desktop: fixed(width: 1256, height: 814) {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const text_indicateure = [
    {
      text: "<p>Le 15 avril 2010, Colas a signé une convention pluriannuelle de mécénat de compétence avec l’établissement public du musée et du domaine national de Versailles, pour la remise en état des allées les plus dégradées et les plus sollicitées du parc du château.</p><p>Les travaux ont concerné principalement l’Esplanade du bassin d’Apollon, la Terrasse nord du château et l’Etoile royale.</p><p>Soucieux de la préservation de l’environnement et du patrimoine, Colas a mis au point pour ce chantier un produit baptisé Héliocol en l’honneur du Roi-Soleil. Composé de granulats locaux provenant de la vallée de la Seine et mélangés à un liant translucide, ce revêtement de couleur beige, sablé à l’aide d’un silex blond, conserve la texture et la teinte historiques des allées du parc. Ce produit innovant confère également aux allées une durabilité supérieure, </p><ul><li>tout en réduisant de </li><li>façon importante les poussières soulevées</li></ul> <p>par les millions de visiteurs en provenance du monde entier qui fréquentent le château chaque année.</p>",
      title:
        "En 2010, un premier engagement avec le Domaine national de Versailles…",
      visuel: data.file.childImageSharp
    },
    {
      text: "<p>En mai 2013, Colas a pris un engagement de même nature en signant avec le Domaine National de Chambord une convention pluriannuelle de mécénat de compétence pour la remise en état des allées aux abords du château.</p><p>La priorité a porté sur l’Allée royale et l’Allée transversale ainsi que sur le jardin anglais.</p><p>Colas a mis en œuvre le même procédé esthétique Héliocol qu’au château de Versailles, afin de conserver la stabilisation et la propreté des allées sans altérer leur caractère patrimonial.</p>",
      title:
        "… suivi en 2013 par un second mécénat de compétence, pour le Domaine national de Chambord",
      visuel: data.file.childImageSharp
    },
  ];

  return (
    <Layout>
      <Seo
        title="Colas Engagement Mecenat Competence"
        description="Mécénat de compétence"
      />
      <div className={classNames("page_template", "page_engagement_mecenat")}>
        <ScrollPageNav
          data={[
            {
              title: 'Notre démarche',
              section: 'block_0_section'
            },
            {
              title: 'Qui sommes-nous ',
              section: 'block_1_section'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: "Acceuil", url: "/" },
                parentPage: { title: "engagement", url: "/engagements" },
                currentPage: {
                  title: "Mécénat de compétence",
                  url: "/mecenat",
                },
              }}
            />
            <TitlePage color="color_white" title="MÉCÉNAT DE COMPÉTENCE" />
          </div>
        </PageBanner>
        <IntroPage
          customClass="block_0_section"
          title="Notre démarche"
          subtitle="Mécénat de compétence dans le parc des châteaux de Versailles et de Chambord : Un mécénat de compétence axé sur la conservation du patrimoine"
          visuel={data.file.childImageSharp}
          text="<p>Au travers de sa démarche de mécénat de compétence, qui allie tradition et modernité, Colas :<p><ul><li>apporte sa contribution à la conservation de hauts lieux de l’Histoire de France, inscrits au patrimoine mondial de l’Humanité,</li> <li>met en avant son métier, ses savoir-faire, ses produits et ses techniques spécifiques, ses solutions en matière de développement responsable,</li> <li>valorise ses équipes chargées de la réalisation des travaux,</li> <li>associe son nom et son image à des références prestigieuses, à forte visibilité internationale.</li></ul>"
          size="border" //wrapper
        />
        <section className={classNames('section_content', 'block_1_section')}>
          <ShapeBackground
            top="right"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className="wrapper_page_xs">
              <TitleSection
                title="Qui sommes-nous :"
              />
              {text_indicateure.map((block, index) => (
                <TextVisuelTwoCol
                  key={index}
                  title={block.title}
                  orderInverse={(index % 2 == 0) ? true : false}
                  visuel={block.visuel}
                  extactHalf={(index % 2 == 0) ? false : true}
                  alignLeft={(index % 2 == 0) ? true : false}
                  alignTop={true}
                  text={block.text}
                  textFullWidth={(index % 2 == 0) ? false : true}
                  smallVisuel={(index % 2 == 0) ? false : true}
                  extremeRight={(index % 2 == 0) ? true : false}
                  visuelAspectRatioMobile={1.34241245}
                  visuelAspectRatioDesktop={(index % 2 == 0) ? 1.04107143 : 0.699801193}
                  visuelMaxWidthDesktop={(index % 2 == 0) ? '100%' : 352}
                />
              ))}
            </div>
          </ShapeBackground>
        </section>
      </div>
    </Layout>
  );
};

export default EngagementMecenat;
